import { useEffect, useRef, useState } from 'react';

/**
 * Возвращает высоту контейнера на всю на весь экран, за исключением header и футер
 * */

export const useContainerHeight = (extraHeight: number = 0) => {
  const ref = useRef<any>(null);
  const footerNode = document.querySelector('#footer');
  const footerHeight = footerNode?.clientHeight || 0;

  const [height, setHeight] = useState<string>('100vh');

  const setHeightContainer = () => {
    const refTop = ref.current?.getBoundingClientRect()?.y;

    const resultHeight = `calc(100vh - ${refTop || 0}px - ${extraHeight}px)`;

    setHeight(resultHeight);
  };

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        setHeightContainer();
      }, 500);
    }
  }, [ref, footerHeight]);

  return { ref, height };
};
