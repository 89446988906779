import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const HeaderWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      gridColumn='2'
      gridRow='1'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 12,
        py: 10,
        px: 12,
        borderRadius: 4,
        bgcolor: '#fff',
      }}
    >
      {children}
    </Box>
  );
};
