import { Box, Button, Grid } from '@mui/material';
import { FC } from 'react';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { Select } from 'shared/ui/select';
import { IStep } from '.';

interface IProps extends IStep {}

export const AccountForm: FC<IProps> = ({ methods, onSubmit, setStep, methodsOptions }) => {
  const methodID = methods.watch('method_id');

  return (
    <FormWrapper onSubmit={onSubmit} methods={methods}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          direction={'column'}
          columnGap={'20px'}
          rowGap={'34px'}
          wrap={'nowrap'}
          gridTemplateColumns={'1fr 1fr'}
          display='grid'
          gridTemplateRows='auto auto 1fr'
        >
          <Grid item>
            <Input
              control={methods.control}
              error={methods.formState.errors}
              placeholder='Text'
              required
              label='Телефон или почта пользователя'
              name='user_name'
              rules={{ required: 'Required field' }}
            />
          </Grid>

          <Grid item>
            <Input
              control={methods.control}
              error={methods.formState.errors}
              required
              placeholder='Text'
              label='Имя пользователя'
              name='account_name'
              rules={{ required: 'Required field' }}
            />
          </Grid>

          <Grid item>
            <Select
              control={methods.control}
              error={methods.formState.errors}
              required
              name='method_id'
              placeholder='Text'
              disabled
              label='Метод'
              rules={{
                required: 'Required field',
              }}
              options={methodsOptions}
            />
          </Grid>
          <Grid item>
            <Input
              control={methods.control}
              error={methods.formState.errors}
              required
              name='account_id'
              placeholder='Text'
              label='Id аккаунта'
              rules={{
                required: 'Required field',
              }}
            />
          </Grid>
          <Grid item>
            <Input
              control={methods.control}
              error={methods.formState.errors}
              placeholder='Text'
              label='Токен для приложения'
              name='password'
              rules={{ maxLength: { message: 'Maximum length - 100', value: 100 } }}
            />
          </Grid>
          <Grid item>
            <Input
              control={methods.control}
              error={methods.formState.errors}
              placeholder='Text'
              label='Логин от личного кабинета'
              name='bank_login'
            />
          </Grid>
          <Grid item>
            <Input
              control={methods.control}
              error={methods.formState.errors}
              placeholder='Text'
              label='Пароль от личного кабинета'
              name='bank_password'
              rules={{ maxLength: { message: 'Maximum length - 100', value: 100 } }}
            />
          </Grid>
          <Grid item>
            <Input
              control={methods.control}
              error={methods.formState.errors}
              placeholder='Text'
              label='Валюта транзакций'
              name='currency'
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={'30px'} display={'flex'} gap={'24px'} alignItems={'center'} justifyContent={'center'}>
        <Button
          variant='contained'
          fullWidth
          onClick={() => setStep(1)}
          sx={{
            maxWidth: 171,
            m: 0,
            borderRadius: 50,
            textTransform: 'none',
            background: '#E3E3E4',
            color: '#1D1B20',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#e2e2e2',
            },
          }}
        >
          Back
        </Button>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ m: 0, borderRadius: 50, textTransform: 'none', maxWidth: 171, boxShadow: 'none' }}
        >
          Save
        </Button>
      </Box>
    </FormWrapper>
  );
};
