import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MethodsService } from 'shared/services/methods.service';
import { TMethods } from 'shared/services/types/methods.types';

export const useSwitchMethod = () => {
  const queryClient = useQueryClient();

  const switchIsActive = useMutation({
    mutationFn: (data: TMethods.SwitchMethod) => MethodsService.switchMethod(data),
    mutationKey: ['switch method'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get methods', 'get applications'] });
    },
  });

  return { switchIsActive };
};
