import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { FilterContext } from 'app/store/filter-context';
import { AddAccountModal } from 'features/add-account';
import { AddMethodModal } from 'features/add-method';
import { useGetApplicationsExport } from 'features/applications/api/useGetApplicationExport';
import { useGetTransactionsExport } from 'features/transactions/api/useGetTransactionExport';
import { WithdrawalModal } from 'features/withdrawal';
import { FC, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DownloadCloudIcon, ExportIcon, FilterAlt, HeaderArrow } from 'shared/assets/icons';
import useUser from 'shared/hooks/useUser';
import { TRoles } from 'shared/services/types/common.types';
import { MethodSelect } from 'shared/ui/method-select';
import { addMessageToast } from 'shared/utils/addMessageToast';
import { downloadExportFile } from 'shared/utils/downloadExportFile';

interface IProps extends PropsWithChildren {
  bankName?: string | undefined;
  isSingleMethod?: boolean;
}

const ROLE_ACCESS = ['Администратор', 'Менеджер'] as TRoles[];

export const Header: FC<IProps> = ({ bankName, isSingleMethod }) => {
  const navigate = useNavigate();
  const { id: useParamsMethodID } = useParams();
  const { filter, setIsFilterOpen } = useContext(FilterContext);
  const { currentMethod, mode } = filter;
  const filterParams = {
    methodID: currentMethod,
    ...filter[currentMethod],
  };
  const methodID = parseInt(useParamsMethodID!);

  const [isOpenWithdrawal, setIsOpenWithdrawal] = useState(false);
  const [isOpenAddMethod, setIsOpenAddMethod] = useState(false);
  const [modalAccountAdd, setModalAccountAdd] = useState(false);

  const { isAdmin, user } = useUser();
  const downloadTransactionsTrigger = useRef(false);
  const downloadApplicationsDataTrigger = useRef(false);
  const {
    applications,
    refetchApplications,
    errorMessage: errorApplications,
    isFetching: isApplicationExportFetching,
  } = useGetApplicationsExport(filterParams);
  const {
    transactions,
    refetchTransactions,
    errorMessage: errorTransactions,
    isTransactionsExportFetching,
  } = useGetTransactionsExport(filterParams);

  const isLoadingDownload = isApplicationExportFetching || isTransactionsExportFetching;
  useEffect(() => {
    if (errorApplications) {
      addMessageToast(errorApplications?.detail);
    }
  }, [errorApplications]);

  useEffect(() => {
    if (errorTransactions) {
      addMessageToast(errorTransactions?.detail);
    }
  }, [errorTransactions]);

  const handleDownloadData = () => {
    if (mode !== 'transactions') {
      refetchApplications();
      downloadApplicationsDataTrigger.current = true;
    }
    if (mode !== 'applications') {
      refetchTransactions();
      downloadTransactionsTrigger.current = true;
    }
  };
  useEffect(() => {
    if (applications && downloadApplicationsDataTrigger.current) {
      downloadExportFile(applications, 'applications');

      downloadApplicationsDataTrigger.current = false;
    }
  }, [applications]);
  useEffect(() => {
    if (transactions && downloadTransactionsTrigger.current) {
      downloadExportFile(transactions, 'transactions');
      downloadTransactionsTrigger.current = false;
    }
  }, [transactions]);

  const filterActive = filter?.[currentMethod] && Object.values(filter?.[currentMethod]).some((item) => item);

  return (
    <Box
      gridColumn='2'
      gridRow='1'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 12,
        py: 7,
        px: 12,
        borderRadius: 4,
        bgcolor: '#fff',
        '.MuiButton-root': {
          paddingInline: '33px',
          minWidth: 'fit-content',
        },
      }}
    >
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '14px' }}>
        {methodID ? (
          <>
            <Box sx={{ height: '44px', cursor: 'pointer' }}>
              <HeaderArrow
                style={{
                  cursor: 'pointer',
                  marginTop: '11.5px',
                }}
                onClick={() => {
                  navigate('/');
                }}
              />
            </Box>
            <Typography variant='h3' color='primary' sx={{ fontSize: 28, lineHeight: '36px', fontWeight: '400' }}>
              Method
            </Typography>
            <Box>
              <MethodSelect useParamsMethodID={useParamsMethodID} />
            </Box>
          </>
        ) : (
          <Typography variant='h3' color='primary' sx={{ fontSize: 28, lineHeight: '36px', fontWeight: '%00' }}>
            Methods
          </Typography>
        )}
      </Box>
      {!methodID && isAdmin && (
        <Button
          variant='contained'
          sx={{ minWidth: 188, py: 5 }}
          startIcon={<Add />}
          onClick={() => {
            setIsOpenAddMethod(true);
          }}
        >
          Add method
        </Button>
      )}
      {!!methodID && user && ROLE_ACCESS.includes(user?.role) && (
        <Button
          variant='contained'
          onClick={() => {
            setModalAccountAdd(true);
          }}
          sx={{ p: '10px 24px', maxWidth: 162 }}
          startIcon={<Add />}
        >
          Add account
        </Button>
      )}

      {isSingleMethod && (
        <>
          {user && ROLE_ACCESS.includes(user?.role) && (
            <Button
              variant='outlined'
              disabled={isLoadingDownload}
              sx={{ minWidth: 188, py: 5, position: 'relative' }}
              startIcon={<DownloadCloudIcon />}
              onClick={handleDownloadData}
            >
              {filterActive && (
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    backgroundColor: 'red',
                    position: 'absolute',
                    top: 12,
                    right: 19,
                  }}
                />
              )}
              Download data
            </Button>
          )}
          <Button
            variant='outlined'
            sx={{ minWidth: 188, py: 5 }}
            startIcon={<ExportIcon />}
            onClick={() => {
              setIsOpenWithdrawal(true);
            }}
          >
            Withdrawal
          </Button>
        </>
      )}
      {!!methodID && (
        <Button
          variant='outlined'
          onClick={() => {
            setIsFilterOpen(true);
          }}
          sx={{ p: '10px 24px', maxWidth: 162 }}
          startIcon={<FilterAlt />}
        >
          Filter
        </Button>
      )}

      <AddMethodModal isOpen={isOpenAddMethod} setIsOpen={setIsOpenAddMethod}></AddMethodModal>
      <WithdrawalModal isOpen={isOpenWithdrawal} setIsOpen={setIsOpenWithdrawal}></WithdrawalModal>
      <AddAccountModal isOpen={modalAccountAdd} setIsOpen={setModalAccountAdd} methodID={methodID} />
    </Box>
  );
};
