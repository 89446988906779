import { Box, CircularProgress, ListItemButton, ListItemText } from '@mui/material';
import { MethodsItem } from 'features/methods-item';
import { useNavigate, useParams } from 'react-router-dom';
import { TMethods } from 'shared/services/types/methods.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { Layout } from 'shared/ui/layout';
import { useGetMethods } from '../api/useGetMethods';

export const Methods = () => {
  const { isMethodsLoading, methods, isMethodsError } = useGetMethods();
  const { id: useParamsMethodID } = useParams();
  const methodID = parseInt(useParamsMethodID!);

  const navigate = useNavigate();
  return (
    <Layout>
      <Box
        gridRow={'2'}
        gridColumn={'2'}
        sx={{
          padding: '14px 24px',
          background: '#fff',
          borderRadius: '16px',
          overflowX: 'scroll',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: 'none',
          }}
        >
          {!methods ? (
            <Box
              sx={{
                minWidth: 1,
                minHeight: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {(methods || []).map((method: TMethods.Method, id: number) => (
                <ListItemButton
                  onClick={() => {
                    navigate('/method/' + method.id);
                  }}
                  sx={{
                    borderRadius: '100px',
                    p: '10px 32px',
                    mx: 3,
                    minWidth: 'auto',
                    position: 'relative',
                    border: '1px solid rgba(24, 90, 194, 0.5)',
                    color: '#185AC2',
                    flexGrow: '0',
                  }}
                  key={id}
                >
                  <ListItemText
                    sx={{
                      m: 0,
                      fontSize: '16px',
                      lineHeight: '20px',
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      letterSpacing: '0.25px',
                      '.MuiTypography-root': {
                        fontSize: '16px',
                        lineHeight: '20px',
                        width: '100%',
                        minWidth: '100%',
                        display: 'block',
                      },
                    }}
                    primary={method.name}
                  />
                </ListItemButton>
              ))}
            </>
          )}
        </Box>
      </Box>
      <Box
        gridRow={'3/4'}
        gridColumn={'2'}
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridAutoRows: 'minmax(350px, calc(50% - 4px))',
          gap: 4,
          borderRadius: '16px',
          overflow: 'scroll',
          maxHeight: 'calc(100% - 24px)',
        }}
      >
        {isMethodsLoading ? (
          <CircularProgressCenter top='120%' />
        ) : (
          (methods || []).map((method) => (
            <MethodsItem
              methodsInfo={{ name: method.name, id: method.id, isActive: method.is_active }}
              key={method.id}
            ></MethodsItem>
          ))
        )}
      </Box>
    </Layout>
  );
};
