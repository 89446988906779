import { GridColDef } from '@mui/x-data-grid';
import { ROWS } from './MOCK_ROW';

export const COLUMNS_A: GridColDef<(typeof ROWS)[number]>[] = [
  {
    field: 'status',
    headerName: '',
  },
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'timestamp',
    headerName: 'Date',
  },
  {
    field: 'transaction',
    headerName: 'Transaction',
  },
  {
    field: 'account_id',
    headerName: 'Account',
  },
  {
    field: 'remarks',
    headerName: 'Remarks',
  },
  {
    field: 'income',
    headerName: 'Income',
  },
  {
    field: 'more',
    headerName: 'AddApplication',
  },
];
