import { Box, Button, Grid } from '@mui/material';
import { useGetAccounts } from 'features/users/api/useGetAccounts';
import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { DownloadIcon } from 'shared/assets/icons';
import { INTEGER, TWO_FACTOR } from 'shared/constants/regex';
import { useCurrentUrl } from 'shared/hooks';
import { TUsers } from 'shared/services/types/users.types';
import { TWithdrawal } from 'shared/services/types/withdrawal.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { Select } from 'shared/ui/select';
import { hasFormValuesChanged } from 'shared/utils';
import { addMessageToast } from 'shared/utils/addMessageToast';
import { useCreateWithdrawal } from './api/useCreateWithdrawal';
import { accountBalance, accountsAvailableOptions, getAccountCurrency } from './helpers';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const defaultFilterU = {
  size: 1000,
  page: 0,
} as TUsers.GetAccount;

export const WithdrawalModal: FC<IProps> = ({ setIsOpen, isOpen }) => {
  const methodID = useCurrentUrl();

  const methods = useForm({ mode: 'onBlur' });
  const formErrors = methods.formState.errors;
  const accountFrom = methods.watch().account_id_from;

  const { isUsersLoading, users, isUsersError } = useGetAccounts({ ...defaultFilterU, method_id: +methodID! });
  const accountCurrency = getAccountCurrency(accountFrom, users);
  const balance = accountBalance(accountFrom, users);
  const maxWithdrawal = balance || 20000;
  const balanceHelperText = balance ? `Balance: ${balance?.toString()} ${accountCurrency}` : undefined;

  const isConfirmationNeeded = hasFormValuesChanged(methods.watch());

  const { create } = useCreateWithdrawal();
  const { reset, control } = methods;

  const onSubmit: SubmitHandler<TWithdrawal.WithdrawalCreate> = async (data) => {
    try {
      await create.mutateAsync(data);
    } catch (error) {
      addMessageToast(error);
    }
  };
  const withdrawalAccounts = accountsAvailableOptions(users);

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={() => {
        reset();
        setIsOpen(false);
      }}
      isOpen={isOpen}
      onBack={() => setIsOpen(false)}
      titleText={'Withdrawal'}
      hideBackdrop={false}
      reset={reset}
      sx={{
        '.MuiPaper-root.close-modal': {
          width: '568px',
        },
      }}
    >
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
            <Grid item>
              <Select
                name='account_id_from'
                label='Withdrawal from account *'
                placeholder='Withdrawal From Sub-Account'
                rules={{
                  validate: (value) => {
                    if (!value) return 'Please, enter account';
                    // TODO  return value && !isAccountActive(value, users) && 'Account is not active';
                  },
                }}
                control={control}
                error={formErrors.account_id_from?.message?.toString()}
                options={withdrawalAccounts ?? []}
                helperText={balanceHelperText}
              />
            </Grid>
            <Grid item>
              <Input
                name='amount'
                label='Amount *'
                placeholder='Enter amount'
                rules={{
                  required: 'Please, enter amount',
                  min: {
                    value: 50,
                    message: 'Amount must be at least 50',
                  },
                  max: {
                    value: maxWithdrawal,
                    message: `Amount must less than or equal to ${maxWithdrawal}`,
                  },
                  pattern: {
                    value: INTEGER,
                    message: 'Please enter a valid amount(only integer allowed)',
                  },
                }}
                control={control}
                error={formErrors.amount?.message?.toString()}
              />
            </Grid>
            <Grid item>
              <Input
                name='account_id_to'
                label='Account number *'
                placeholder='Enter account for withdrawal'
                rules={{
                  required: 'Please, enter account',
                  pattern: {
                    value: INTEGER,
                    message: 'Please enter a valid account(only digits allowed)',
                  },
                  minLength: {
                    value: 3,
                    message: 'Too short account name',
                  },
                }}
                control={control}
                error={formErrors.account_id_to?.message?.toString()}
              />
            </Grid>
            <Grid item>
              <Input
                name='auth_code'
                label='Two-factor authentication *'
                placeholder='6-digit code'
                rules={{
                  required: 'Please, enter 6-digit-code.',
                  pattern: { value: TWO_FACTOR, message: 'Please, enter 6-digit-code.' },
                }}
                control={control}
                error={formErrors.auth_code?.message?.toString()}
              />
            </Grid>
          </Grid>
        </Box>

        <Box display={'flex'} gap='10px' mt={15} justifyContent='center'>
          <Button
            fullWidth
            variant='outlined'
            onClick={() => setIsOpen(false)}
            sx={{ borderRadius: 50, textTransform: 'none', height: 40, width: 175 }}
          >
            Back
          </Button>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ borderRadius: 50, textTransform: 'none', height: 40, width: 175 }}
            startIcon={<DownloadIcon />}
          >
            Send
          </Button>
        </Box>
      </FormWrapper>
    </ModalTemplate>
  );
};
