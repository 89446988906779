import { Box, Button } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TMethods } from 'shared/services/types/methods.types';
import { ModalTemplate } from 'shared/ui/modal-template';
import { addMessageToast } from 'shared/utils/addMessageToast';
import { useCreateMethod } from '../api/useCreateMethod';
import { MainInfoModal, MessageParsingModal, SelectedTextModal } from './slices';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IStepProps {
  onBack?: () => void;
  onNext?: () => void;
}

export const AddMethodModal: FC<IProps> = ({ setIsOpen, isOpen }) => {
  const [stepMainForm, setStepMainForm] = useState<number>(0);
  const [step, setStep] = useState('main');
  const [regexData, setRegexData] = useState<Record<number, { regex: string; names: string[] }>>({});
  const [text, setText] = useState<string[]>([]);
  const { create } = useCreateMethod();
  const methodsStep1 = useForm({ mode: 'onBlur' });
  const methodsStep2 = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (!isOpen) {
      setStepMainForm(0);
      setStep('main');
      setRegexData({});
      setText([]);
      methodsStep1.reset();
      methodsStep2.reset();
    }
  }, [isOpen]);

  const onSelectStepModal = (step: string) => {
    setStep(step);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onSubmit: SubmitHandler<TMethods.CreateMethod> = async (data) => {
    const commonData = { ...methodsStep1.getValues(), ...methodsStep2.getValues, ...data } as TMethods.CreateMethod;
    const regex = Object.values(regexData).map((item) => `${item.regex}:$:${item.names.join(',')}`);

    function escapeString(str: string) {
      return str
        .replace(/\\/g, '\\\\') // Экранируем обратные косые черты
        .replace(/"/g, '\\"') // Экранируем двойные кавычки
        .replace(/$$/g, '\\[') // Экранируем открывающую квадратную скобку
        .replace(/\\\[$/, '');
    }
    const regexString = JSON.stringify(regex).replace(/\\\\/g, '\\');

    const requestData = {
      name: commonData.name,
      min_value: commonData.min_value,
      decline_time: commonData.decline_time,
      currency: commonData.currency,
      regex: escapeString(regexString),
      compare_field: commonData.compare_field,
      ussd_command: commonData.ussd_command,
    } as TMethods.CreateMethod;

    try {
      await create.mutateAsync(requestData);
      addMessageToast('Method successfully added', 'success');
      onClose();
    } catch (error) {
      addMessageToast(error);
    }
  };

  useEffect(() => {
    const reg = Object.values(regexData);
    if (reg?.[0]?.regex) {
      methodsStep2.clearErrors();
      methodsStep2.setValue(
        'regex',
        reg?.map((v) => v.regex)
      );
    }
  }, [regexData]);

  const modal = {
    main: (
      <MainInfoModal
        formOne={methodsStep1}
        activeTab={stepMainForm}
        formTwo={methodsStep2}
        onNext={() => setStepMainForm(1)}
        isOpen={isOpen}
        close={onClose}
        onSubmit={onSubmit}
        onRegexEditor={() => onSelectStepModal('addPushConfirm')}
      />
    ),
    selectedText: (
      <SelectedTextModal
        isOpen={isOpen}
        setText={(text) => setText(text)}
        close={() => onClose()}
        onNext={() => onSelectStepModal('messageParsing')}
        onBack={() => onSelectStepModal('main')}
      />
    ),
    messageParsing: (
      <MessageParsingModal
        isOpen={isOpen}
        texts={text}
        setRegexInfo={setRegexData}
        close={() => onClose()}
        onNext={() => {
          onSelectStepModal('modal');
        }}
        onBack={() => onSelectStepModal('selectedText')}
      />
    ),
  };

  //@ts-ignore
  return modal[step] || modal.main;
};
