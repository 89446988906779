export const API_URL = `https://test-api.cashdash.cloud/`;

export const getTransactionUrl = (string: string) => `/transaction${string}`;
export const getUserUrl = (string: string) => `/user${string}`;
export const getMethodUrl = (string: string) => `/method${string}`;
export const getMessageUrl = (string: string) => `/message${string}`;
export const getApplicationUrl = (string: string) => `/application${string}`;
export const getStatisticsUrl = (string: string) => `/statistics${string}`;
export const getWithdrawalUrl = (string: string) => `/withdrawal${string}`;
export const getTwoFactorUrl = (string: string) => `/2fa${string}`;
