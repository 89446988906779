import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, SxProps, TableCell, TableRow, Theme } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { ROLE_USER } from 'shared/constants';
import { useContainerHeight } from 'shared/hooks';
import { TMethods } from 'shared/services/types/methods.types';
import { TUsers } from 'shared/services/types/users.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { TableLayout } from 'shared/ui/table-layout';
import { COLUMNS_USERS } from 'widgets/method/mock-data/COL_USERS';

interface IProps {
  usersData?: TUsers.GetUsersSuccess;
  isUsersFetching?: boolean;
  isUsersLoading?: boolean;
  onEditUser: (data: TUsers.UserData) => void;
  methods?: TMethods.Method[];
  userIds?: number[];
  onToggleUsers: (id: number) => void;
  setFilter: React.Dispatch<
    React.SetStateAction<{
      page: number;
      size: number;
    }>
  >;
}
export const TableUsers: FC<IProps> = ({
  usersData,
  isUsersFetching,
  isUsersLoading,
  onEditUser,
  methods,
  userIds,
  setFilter,
}) => {
  const { height, ref } = useContainerHeight(15);
  const [size, setSize] = useState(20);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setFilter({ size, page });
  }, [size, page]);

  const customTableStyles = {
    '.MuiTableHead-root': {
      '.MuiTableCell-root': {
        background: '#D9E2FF',
        p: '18px 0',
        height: '20px',
        lineHeight: '20px',
        '&:first-child': {
          paddingLeft: '24px',
        },
      },
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        height: '20px',
        padding: '16px 0',
        borderBottom: '1px solid #BDC1D2',
        color: '#000',
        fontWeight: '500',
        '&:first-child': {
          paddingLeft: '24px',
        },
      },
    },
  } as SxProps<Theme>;

  return (
    <Box
      gridRow={'2/4'}
      gridColumn={'2'}
      sx={{
        gap: 4,
        maxHeight: 1,
        overflow: 'clip',
      }}
    >
      <Box
        sx={{
          gridTemplateColumns: 'minmax(450px,1fr) minmax(450px, 1fr)',
          gridTemplateRows: '100%',
          gap: 4,
          overflowX: 'hidden',
        }}
      >
        <Box
          px={12}
          sx={{
            bgcolor: '#fff',
            borderRadius: 4,
            display: 'grid',
            gridTemplateRows: '0 auto',
            minHeight: '350px',
            p: '0',

            height: height,
          }}
          ref={ref}
        >
          <Box
            sx={{
              width: '100%',
              gridRow: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              overflow: 'hidden',
              mr: '-25px',
              pr: '25px',
              ...customTableStyles,
            }}
          >
            <TableLayout
              pageSize={size}
              setPageSize={setSize}
              page={page}
              setPage={setPage}
              small
              rows={usersData?.items || []}
              columns={COLUMNS_USERS}
              total={usersData?.total || 0}
            >
              {isUsersLoading ? (
                <CircularProgressCenter top='120%' />
              ) : (
                <>
                  {usersData?.items?.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        {/* //TODO добавить удаление */}
                        {/* <TableCell align='left'>
                        <Checkbox
                          value={userIds.includes(row.id)}
                          onChange={() => {
                            onToggleUsers(row.id);
                          }}
                        /> */}
                        {/* </TableCell> */}
                        <TableCell component='th' scope='row'>
                          {row.name}
                        </TableCell>
                        <TableCell align='left'>{row.email}</TableCell>
                        <TableCell align='left'>{ROLE_USER.find((item) => item.label === row.role)?.value}</TableCell>
                        <TableCell align='left'>{methods?.find((item) => item.id === row.method_id)?.name}</TableCell>
                        <TableCell align='left'>-</TableCell>
                        <TableCell align='left'>{row.birthday}</TableCell>
                        <TableCell align='left' sx={{ cursor: 'pointer' }}>
                          <Box display={'flex'} gap={10} justifyContent={'flex-end'}>
                            <ModeEditOutlineOutlinedIcon
                              onClick={() => {
                                onEditUser(row);
                              }}
                              style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
                            />
                            {/* //TODO добавить удаление */}
                            {/* <DeleteOutlineSharpIcon style={{ color: '#BA1A1A', width: '24px', height: '24px' }} /> */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
            </TableLayout>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
